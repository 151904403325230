import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  sectionSettings,
  unitConverter as uc,
} from '../../../../../styles/base';
import SuiteIconLinkSubheading from '../../../../molecules/SuiteIconLinkSubheading/SuiteIconLinkSubheading';

const SuiteSection = ({ menuSectionConfig, suiteSectionItems }) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;
  const sectionCss = css`
    width: auto;
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};

    @media (${breakpoints.mobile}) {
      width: 100%;
      padding: ${uc('10px 0')};
    }
  `;

  const renderedComponents = suiteSectionItems.map(data => (
    <SuiteIconLinkSubheading key={data._key} {...data} />
  ));

  return <div css={sectionCss}>{renderedComponents}</div>;
};

const iconLinkSubheadingShape = {
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  headingText: PropTypes.string,
  subheadingText: PropTypes.string,
  subheadingTextHighlight: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  suiteType: PropTypes.string,
  displayName: PropTypes.string,
  shortDescription: PropTypes.string,
};

SuiteSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  suiteSectionItems: PropTypes.arrayOf(
    PropTypes.shape(iconLinkSubheadingShape)
  ),
};

SuiteSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  suiteSectionItems: [],
};

export default SuiteSection;
