import { useContext } from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';

import {
  breakpoints,
  colors,
  sectionSettings,
  unitConverter as uc,
} from '../../../../../styles/base';
import ProductCategoryDrawerV1 from '../../../../molecules/ProductCategoryDrawerV1/ProductCategoryDrawerV1';
import useLocalize from '../../../../../hooks/useLocalize';
import { LocaleContext } from '../../../../../context/LocaleContext';

const ProductCategorySection = ({
  menuSectionConfig,
  productCategorySectionItems,
}) => {
  const query = graphql`
    {
      productCategories: allSanityProductCategoryEntity(
        sort: { fields: productOrdering, order: ASC }
      ) {
        nodes {
          csodId
          displayName: _rawDisplayName(resolveReferences: { maxDepth: 100 })
          shortDescription: _rawShortDescription(
            resolveReferences: { maxDepth: 100 }
          )
          productOrdering
          _type
          linkWithoutTitle {
            link {
              ... on SanityRoute {
                id
                routeName: _rawRouteName(resolveReferences: { maxDepth: 100 })
                slug {
                  current
                  _type
                }
              }
            }
          }
        }
      }
    }
  `;

  const { productCategories } = useStaticQuery(query);

  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    width: auto;
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};

    @media (${breakpoints.mobile}) {
      width: 100%;
      padding: ${uc('10px 0')};
    }
  `;

  const productCategoryEntities =
    productCategorySectionItems || productCategories.nodes;
  const renderedComponents = productCategoryEntities.map(rawData => {
    const { locale } = useContext(LocaleContext);
    const data = useLocalize(rawData, locale);

    return (
      <ProductCategoryDrawerV1 key={`${data.productOrdering}`} {...data} />
    );
  });

  return <div css={sectionCss}>{renderedComponents}</div>;
};

const iconLinkSubheadingShape = {
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  headingText: PropTypes.string,
  subheadingText: PropTypes.string,
  subheadingTextHighlight: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  suiteType: PropTypes.string,
};

ProductCategorySection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  productCategorySectionItems: PropTypes.arrayOf(
    PropTypes.shape(iconLinkSubheadingShape)
  ),
};

ProductCategorySection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  productCategorySectionItems: [],
};

export default ProductCategorySection;
