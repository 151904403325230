import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';
import Link from '../../atoms/Link/Link';
import { Heading2 } from '../../atoms/Headings/Headings';
import Subheading from '../../atoms/Subheading/Subheading';

import CareersSuiteIcon from '../../../../static/suiteAvatars/careersAvatar.svg';
import ContentAnytimeSuiteIcon from '../../../../static/suiteAvatars/contentAnytimeAvatar.svg';
import DevelopmentSuiteIcon from '../../../../static/suiteAvatars/developmentAvatar.svg';
import HRSuiteIcon from '../../../../static/suiteAvatars/hrAvatar.svg';
import LearningSuiteIcon from '../../../../static/suiteAvatars/learningAvatar.svg';
import PerformanceSuiteIcon from '../../../../static/suiteAvatars/performanceAvatar.svg';
import RecruitingSuiteIcon from '../../../../static/suiteAvatars/recruitingAvatar.svg';

const IconLinkSubheading = ({
  headingText,
  HeadingType = Heading2,
  image,
  imageWidth,
  imageHeight,
  link,
  noImage = false,
  subheadingText,
  subheadingTextHighlight,
  suiteType,
}) => {
  let headingColor = colors.primary;
  let headingHoverColor = colors.primaryHover;
  if (suiteType) {
    headingColor = colors[suiteType].main;
    headingHoverColor = colors[suiteType].hover;
  }
  // const fluidProps = getFluidGatsbyImage(image.asset._id,
  // { maxWidth: 1024 }, sanityConfig);
  const iconLinkSubheadingCss = css`
    display: flex;
    padding: ${uc('10px 30px')};
    text-decoration: none;

    &:first-of-type {
      padding-top: ${uc('30px')};

      @media (${breakpoints.tablet}) {
        padding-top: ${uc('15px')};
      }
    }

    &:last-of-type {
      padding-bottom: ${uc('30px')};

      @media (${breakpoints.tablet}) {
        padding-bottom: ${uc('10px')};
      }
    }

    &:hover {
      cursor: pointer;

      ${Heading2} {
        color: ${headingHoverColor};
      }

      ${Subheading} {
        color: ${colors.darkGray.one};
      }
    }

    ${Heading2},
    ${Subheading} {
      transition: ${durations.fast};
    }

    ${Heading2} {
      letter-spacing: normal;
      white-space: nowrap;
    }

    ${Subheading} {
      white-space: normal;

      @media (${breakpoints.tablet}) {
        white-space: normal;
      }
    }
  `;

  const imageCss = css`
    flex-shrink: 0;
    width: ${uc(imageWidth)};
    height: ${uc(imageHeight)};
    margin-right: ${uc('20px')};
  `;

  const heading2Css = css`
    margin-top: 5px;
    margin-bottom: 0;
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.proximaSoft};

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.fifteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fifteen};
    }
  `;

  const subheadingHighlightCss = css`
    color: ${colors.darkGray.three};
  `;

  const subheadingCss = css`
    font-weight: ${fontWeights.medium};
  `;

  const textBlockCss = css`
    line-height: 1.6;
  `;

  let productCategorySVG;

  if (suiteType === 'careers') productCategorySVG = CareersSuiteIcon;
  if (suiteType === 'contentAnytime')
    productCategorySVG = ContentAnytimeSuiteIcon;
  if (suiteType === 'development') productCategorySVG = DevelopmentSuiteIcon;
  if (suiteType === 'hr') productCategorySVG = HRSuiteIcon;
  if (suiteType === 'learning') productCategorySVG = LearningSuiteIcon;
  if (suiteType === 'recruiting') productCategorySVG = RecruitingSuiteIcon;
  if (suiteType === 'performance') productCategorySVG = PerformanceSuiteIcon;

  const iconImg = suiteType ? (
    <img src={productCategorySVG} alt="product suite icon" />
  ) : (
    <img
      src={sanityImage(image)
        .auto('format')
        .width(parseInt(imageWidth, 10))
        .fit('max')
        .url()}
      alt="icon"
    />
  );

  const renderedImg = noImage ? '' : iconImg;

  return (
    <Link
      className="icon-link-subheading"
      to={link}
      css={iconLinkSubheadingCss}
    >
      <div css={imageCss}>{renderedImg}</div>
      <div css={textBlockCss}>
        <HeadingType
          css={heading2Css}
          color={headingColor}
          className="icon-link-subheading-heading-d7"
        >
          {headingText}
        </HeadingType>
        <Subheading
          css={subheadingCss}
          fontSize={fontSizes.fourteen}
          color={colors.mediumGray}
        >
          {subheadingText}
          <span css={subheadingHighlightCss}>
            {` ${subheadingTextHighlight}`}
          </span>
        </Subheading>
      </div>
    </Link>
  );
};

IconLinkSubheading.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  subheadingTextHighlight: PropTypes.string,
  subheadingText: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  HeadingType: PropTypes.shape({}),
  noImage: PropTypes.bool,
  suiteType: PropTypes.string,
};

IconLinkSubheading.defaultProps = {
  imageWidth: '50px',
  imageHeight: '50px',
  subheadingTextHighlight: '',
  HeadingType: Heading2,
  noImage: false,
  suiteType: '',
};

export default IconLinkSubheading;
