import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';
import Link from '../../atoms/Link/Link';
import { Heading2 } from '../../atoms/Headings/Headings';
import Subheading from '../../atoms/Subheading/Subheading';

import CareersCategoryIcon from '../../../../static/suiteAvatars/careersAvatar.svg';
import ContentAnytimeCategoryIcon from '../../../../static/suiteAvatars/contentAnytimeAvatar.svg';
import DevelopmentCategoryIcon from '../../../../static/suiteAvatars/developmentAvatar.svg';
import HRCategoryIcon from '../../../../static/suiteAvatars/hrAvatar.svg';
import LearningCategoryIcon from '../../../../static/suiteAvatars/learningAvatar.svg';
import PerformanceCategoryIcon from '../../../../static/suiteAvatars/performanceAvatar.svg';
import RecruitingCategoryIcon from '../../../../static/suiteAvatars/recruitingAvatar.svg';

const ProductCategoryItemV1 = ({
  displayName,
  linkWithoutTitle,
  HeadingType = Heading2,
  shortDescription,
}) => {
  let headingColor = colors.primary;
  let headingHoverColor = colors.primaryHover;

  let productCategory = displayName.replace('Cornerstone ', '').toLowerCase();
  productCategory =
    productCategory === 'content' ? 'contentAnytime' : productCategory;
  if (productCategory) {
    headingColor = colors[productCategory].main;
    headingHoverColor = colors[productCategory].hover;
  }

  const productCategoryItemV1Css = css`
    display: flex;
    padding: ${uc('10px 30px')};
    text-decoration: none;

    &:first-of-type {
      padding-top: ${uc('30px')};

      @media (${breakpoints.tablet}) {
        padding-top: ${uc('15px')};
      }
    }

    &:last-of-type {
      padding-bottom: ${uc('30px')};

      @media (${breakpoints.tablet}) {
        padding-bottom: ${uc('10px')};
      }
    }

    &:hover {
      cursor: pointer;

      ${Heading2} {
        color: ${headingHoverColor};
      }

      ${Subheading} {
        color: ${colors.darkGray.one};
      }
    }

    ${Heading2},
    ${Subheading} {
      transition: ${durations.fast};
    }

    ${Heading2} {
      letter-spacing: normal;
      white-space: nowrap;
    }

    ${Subheading} {
      white-space: normal;

      @media (${breakpoints.tablet}) {
        white-space: normal;
      }
    }
  `;

  const imageCss = css`
    flex-shrink: 0;
    width: ${uc('40px')};
    height: auto;
    margin-right: ${uc('20px')};
  `;

  const heading2Css = css`
    margin-top: 5px;
    margin-bottom: 0;
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.proximaSoft};

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.fifteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fifteen};
    }
  `;

  const subheadingHighlightCss = css`
    color: ${colors.darkGray.three};
  `;

  const subheadingCss = css`
    font-weight: ${fontWeights.medium};
  `;

  const textBlockCss = css`
    line-height: 1.6;
  `;

  let productCategorySVG;

  switch (productCategory) {
    case 'careers':
      productCategorySVG = CareersCategoryIcon;
      break;
    case 'contentAnytime':
      productCategorySVG = ContentAnytimeCategoryIcon;
      break;
    case 'development':
      productCategorySVG = DevelopmentCategoryIcon;
      break;
    case 'hr':
      productCategorySVG = HRCategoryIcon;
      break;
    case 'learning':
      productCategorySVG = LearningCategoryIcon;
      break;
    case 'recruiting':
      productCategorySVG = RecruitingCategoryIcon;
      break;
    case 'performance':
      productCategorySVG = PerformanceCategoryIcon;
      break;
    default:
      break;
  }

  const renderedImg = <img src={productCategorySVG} alt="product suite icon" />;

  return (
    <Link
      className="icon-link-subheading"
      to={linkWithoutTitle}
      css={productCategoryItemV1Css}
    >
      <div css={imageCss}>{renderedImg}</div>
      <div css={textBlockCss}>
        <HeadingType
          css={heading2Css}
          color={headingColor}
          className="icon-link-subheading-heading-d7"
        >
          {displayName}
        </HeadingType>
        <Subheading
          css={subheadingCss}
          fontSize={fontSizes.fourteen}
          color={colors.mediumGray}
        >
          {shortDescription}
          <span css={subheadingHighlightCss}> {` ${''}`} </span>
        </Subheading>
      </div>
    </Link>
  );
};

ProductCategoryItemV1.propTypes = {
  linkWithoutTitle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  HeadingType: PropTypes.shape({}),
  displayName: PropTypes.string,
  shortDescription: PropTypes.string,
};

ProductCategoryItemV1.defaultProps = {
  HeadingType: Heading2,
  displayName: '',
  shortDescription: '',
};

export default ProductCategoryItemV1;
