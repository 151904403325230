import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { colors, lineHeights, unitConverter as uc } from '../../../styles/base';
import { Heading2 } from '../../atoms/Headings/Headings';
import IconLinkSubheading from '../IconLinkSubheading/IconLinkSubheading';

const SuiteIconLinkSubheading = ({ suiteType, ...props }) => {
  const suiteIconLinkSubheadingCss = css`
    width: ${suiteType === 'overview' ? uc('230px') : 'auto'};

    ${Heading2},
    h2 {
      /* storybook won't pick up the color unless we use h2 */
      color: ${colors[suiteType]};
      line-height: ${lineHeights.copy};
      letter-spacing: normal;
    }

    &:hover {
      ${Heading2},
      h2 {
        color: ${colors[`${suiteType}Hover`]};
      }
    }

    > div {
      width: auto;
    }

    img {
      width: ${uc('34px')};
      height: auto;
    }
  `;

  return (
    <IconLinkSubheading
      css={suiteIconLinkSubheadingCss}
      {...props}
      suiteType={suiteType}
    />
  );
};

SuiteIconLinkSubheading.propTypes = {
  image: PropTypes.shape({}),
  imageWidth: PropTypes.string,
  headingText: PropTypes.string,
  subheadingTextHighlight: PropTypes.string,
  subheadingText: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  suiteType: PropTypes.string,
};

SuiteIconLinkSubheading.defaultProps = {
  image: {},
  imageWidth: '40px',
  headingText: 'headingText',
  subheadingText: '',
  subheadingTextHighlight: '',
  link: 'http://csod.com',
  suiteType: '',
};

export default SuiteIconLinkSubheading;
