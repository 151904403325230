import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { lineHeights, unitConverter as uc } from '../../../styles/base';
import { Heading2 } from '../../atoms/Headings/Headings';
import ProductCategoryItemV1 from '../ProductCategoryItemV1/ProductCategoryItemV1';

const ProductCategoryDrawerV1 = ({ ...props }) => {
  const productCategoryDrawerV1Css = css`
    ${Heading2},
    h2 {
      /* storybook won't pick up the color unless we use h2 */
      line-height: ${lineHeights.copy};
      letter-spacing: normal;
    }

    > div {
      width: auto;
    }

    img {
      width: ${uc('34px')};
      height: auto;
    }
  `;

  return <ProductCategoryItemV1 css={productCategoryDrawerV1Css} {...props} />;
};

ProductCategoryDrawerV1.propTypes = {
  displayName: PropTypes.string,
  shortDescription: PropTypes.string,
};

ProductCategoryDrawerV1.defaultProps = {
  displayName: '',
  shortDescription: '',
};

export default ProductCategoryDrawerV1;
